import React from 'react'
import { Badge } from '@momentum-ui/react'

interface IChangelogBadgeProps {
  type: string
}

const ApiChangelogBadge: React.FC<IChangelogBadgeProps> = ({
  type,
}): JSX.Element => {
  type = type.trim()
  const badgeColor = (type: string): string => {
    const colors: { [key: string]: string } = {
      NEW: 'green-pastel',
      UPDATE: 'blue-pastel',
      WARNING: 'yellow-pastel',
      MAJOR: 'yellow-pastel',
      DEPRECATION: 'red-pastel',
      BREAKING: 'red-pastel',
    }
    return colors[type.toUpperCase()] !== undefined
      ? colors[type.toUpperCase()]
      : 'pastel'
  }

  const badgeClass = (type: string): string => {
    const className: { [key: string]: string } = {
      NEW: 'changelog-new',
      UPDATE: 'changelog-update',
      WARNING: 'changelog-warning',
      MAJOR: 'changelog-warning',
      DEPRECATION: 'changelog-breaking',
      BREAKING: 'changelog-breaking',
    }

    return className[type.toUpperCase()] !== undefined
      ? className[type.toUpperCase()]
      : 'changelog-feature'
  }

  const badgeTitle = (type: string): string => {
    const title: { [key: string]: string } = {
      GRPC: 'gRPC',
      GRAPHQL: 'GraphQL',
      REST: 'REST',
    }

    return title[type.toUpperCase()] !== undefined
      ? title[type.toUpperCase()]
      : type.replace(/\b\w/g, function (match) {
          return match.toUpperCase()
        })
  }

  return (
    <Badge
      className={`changelog-badge ${badgeClass(type)}`}
      color={badgeColor(type)}
    >
      {badgeTitle(type)}
    </Badge>
  )
}

export default ApiChangelogBadge
