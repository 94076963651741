import React from 'react'
import dayjs from 'dayjs'
import ReactMarkdown from 'react-markdown'
import ApiChangelogBadge from '../ApiChangelogBadge'

interface IChangeLogEntryProps {
  entry: {
    type?: string
    effectiveDate: Date
    description: string
    changeArea: string
  }
}

const ApiChangelogEntry: React.FC<IChangeLogEntryProps> = ({
  entry,
}): JSX.Element => {
  return (
    <section className="changelog-entry">
      <h2>{dayjs(entry.effectiveDate).format('MMMM D, YYYY')}</h2>
      <div className="changelog-body">
        <div>
          <ApiChangelogBadge type={entry.changeArea} />{' '}
          {entry.type ? <ApiChangelogBadge type={entry.type} /> : null}
        </div>

        <ul>
          <li>
            <ReactMarkdown className="description" source={entry.description} />
          </li>
        </ul>
      </div>
    </section>
  )
}

export default ApiChangelogEntry
