import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Seo from '../../components/Seo'
import { PAGE_VIEWED } from '../../constants/metrics'
import DocumentationLayout from '../../layouts/DocumentationLayout'
import MetricsService from '../../services/metricsService'
import ApiChangelogEntry from '../../components/ApiChangelogEntry'
import TableOfContents from '../../components/TableOfContents'
import { Icon } from '@momentum-ui/react'

interface ChangeLogEntry {
  type?: string
  effectiveDate: Date
  description: string
  changeArea: string
}

interface IChangelogProps {
  pageContext: {
    filterDate: DataView
  }
  data: {
    allStrapiChangeLogs: {
      nodes: Array<ChangeLogEntry>
    }
  }
}

interface IChangelogMonthAndContent {
  year: string
  month: string
  content: Array<ChangeLogEntry>
}

export const query = graphql`
  query ChangelogsQuery($filterDate: Date) {
    allStrapiChangeLogs(
      filter: { effectiveDate: { lte: $filterDate } }
      sort: { fields: [effectiveDate], order: DESC }
    ) {
      nodes {
        type
        effectiveDate
        description
        changeArea
      }
    }
  }
`

const PageChangelog: React.FC<IChangelogProps> = ({ data }): JSX.Element => {
  const changelogEntries = data.allStrapiChangeLogs?.nodes
  const metricsService = MetricsService.getInstance()
  const { t } = useTranslation()
  const pageName = 'Changelog'

  metricsService.track(PAGE_VIEWED, {
    pageName: pageName,
  })

  const generatedToc: Array<any> = []
  let changelogYears: Array<any> = []
  let changelogYearsAndMonths: Array<any> = []
  let changelogYearsAndMonthsContent: Array<any> = []

  if (changelogEntries) {
    changelogYears = [
      ...new Set(
        changelogEntries.map((changelogEntry) =>
          dayjs(changelogEntry.effectiveDate).format('YYYY')
        )
      ),
    ]

    changelogYearsAndMonths = changelogYears.map((entry) => {
      return {
        year: entry,
        months: [
          ...new Set(
            changelogEntries
              .filter(
                (changelogEntry) =>
                  dayjs(changelogEntry.effectiveDate).format('YYYY') === entry
              )
              .map((changelogEntry) =>
                dayjs(changelogEntry.effectiveDate).format('MMMM')
              )
          ),
        ],
      }
    })

    changelogYearsAndMonthsContent = changelogYearsAndMonths.map(
      (yearMonth) => {
        generatedToc.push({
          label: yearMonth.year,
          level: 2,
        })
        return yearMonth.months.map((month: string) => {
          generatedToc.push({
            label: month,
            level: 3,
            href: `${month.toLowerCase()}-${yearMonth.year}`,
          })
          return {
            year: yearMonth.year,
            month,
            content: changelogEntries.filter(
              (changelog) =>
                dayjs(changelog.effectiveDate).format('YYYY-MMMM') ===
                `${yearMonth.year}-${month}`
            ),
          }
        })
      }
    )
  }

  return (
    <DocumentationLayout>
      <Seo title={pageName} />
      <div className="guide-container">
        {changelogEntries?.length && (
          <article className={generatedToc ? 'with-toc' : ''}>
            <section className="content">
              <h1>{t('changelog.title')}</h1>
              <p>{t('changelog.description')}</p>
              {changelogYearsAndMonthsContent.map((yearEntry) =>
                yearEntry.map(
                  (monthEntry: IChangelogMonthAndContent, index: number) => (
                    <div key={index}>
                      <div
                        id={`${monthEntry.month.toLowerCase()}-${
                          monthEntry.year
                        }`}
                      />
                      {monthEntry.content.map(
                        (
                          changelogContentEntry: ChangeLogEntry,
                          index: number
                        ) => (
                          <div key={index}>
                            <ApiChangelogEntry entry={changelogContentEntry} />
                            <hr />
                          </div>
                        )
                      )}
                    </div>
                  )
                )
              )}
            </section>
            <section className="side-panel changelog-side-panel">
              <div className="changelog-icon-container">
                <a
                  href="/changelog-feed.xml"
                  target="_blank"
                  className="icon-button-black"
                >
                  {<Icon name="icon-rss-circle_32" />}
                </a>

                <p>{t('changelog.rss')}</p>
              </div>
              <TableOfContents tableOfContentsList={generatedToc} />
            </section>
          </article>
        )}
      </div>
    </DocumentationLayout>
  )
}

export default PageChangelog
